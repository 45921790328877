import { ClassesListContext } from '../../pages/list/duck/context';
import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Flex,
  Grid,
  Tag,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { sanitize } from 'dompurify';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';
import { Icon } from '@lon/shared/components';
import { routes } from '@lon/suit/configs';
import { classCardTypes } from './duck';
import { Actions } from './components';

const ClassCard: React.FC<classCardTypes.CardProps> = ({
  classItem,
  isSelectedItem,
  firstElementRef,
  lastElementRef,
  ...rest
}) => {
  const { t } = useTranslation();
  const { totalCount } = useContext(ClassesListContext);

  return (
    <Card
      as="li"
      aria-label={classItem?.name}
      aria-setsize={totalCount}
      {...rest}
    >
      <CardHeader
        as={Grid}
        gridAutoColumns="repeat(2, fit-content)"
        gridTemplateAreas="'badge button'"
        alignItems="center"
        pl={6}
        pr={3}
        pt={4}
        pb={0}
        justifyContent="space-between"
      >
        {classItem?.archived && (
          <Tag bg="secondary.800" variant="solid" textTransform="uppercase">
            {t('classList.archivedLabel')}
          </Tag>
        )}
        <Actions
          ref={firstElementRef as React.RefObject<HTMLButtonElement>}
          classItem={classItem}
          isSelectedItem={isSelectedItem}
        />
      </CardHeader>
      <CardBody
        as={Flex}
        direction="column"
        justifyContent="space-between"
        px={8}
        pb={5}
        pt={2}
        gap={4}
      >
        <Box minH="6">
          <Text variant="s1" as="span" className="notranslate">
            {classItem?.name}
          </Text>
        </Box>
        <Flex
          direction="column"
          justifyContent="space-between"
          gap="4"
          flexGrow="1"
        >
          <Flex gap={2} flexWrap="wrap">
            {classItem?.classTeachers?.collection?.map((teacher) => (
              <Tooltip
                key={teacher?._id}
                variant="dark"
                label={sanitize(
                  t(
                    teacher?.mainTeacher
                      ? 'classList.mainTeacherTooltip'
                      : 'classList.teacherTooltip',
                    {
                      name: `${teacher?.teacher.firstName} ${teacher?.teacher.lastName}'`,
                      interpolation: {
                        escapeValue: false,
                      },
                    }
                  )
                )}
              >
                <Tag
                  variant="classTeacher"
                  display="flex"
                  px={3}
                  py={1.5}
                  gap={1.5}
                  cursor="default"
                >
                  {teacher?.mainTeacher && (
                    <Box h="2" w="2" bgColor="danger.600" borderRadius="50%" />
                  )}
                  <Text
                    as="span"
                    variant="tag"
                    overflowWrap="anywhere"
                    className="notranslate"
                  >
                    {`${teacher?.teacher.firstName} ${teacher?.teacher.lastName}`}
                  </Text>
                </Tag>
              </Tooltip>
            ))}
          </Flex>
          <Flex gap={2}>
            <Tag variant="infoOutline" size="sm" height={5}>
              <Text as="span" variant="tsu" className="notranslate">
                {classItem?.curriculumArea}
              </Text>
            </Tag>
            {classItem?.gradeOrCourse && (
              <Tag variant="infoOutlineWithBgr" size="sm" height={5}>
                <Text as="span" variant="tsu" className="notranslate">
                  {classItem?.gradeOrCourse}
                </Text>
              </Tag>
            )}
          </Flex>
        </Flex>
      </CardBody>
      <Divider m={0} />
      <CardFooter
        justify="space-between"
        px={6}
        pb={3}
        pt={2}
        aria-live="polite"
      >
        <Tooltip variant="dark" label={t('classList.gradebookTooltip')}>
          <Button
            as={Link}
            to={generatePath(routes.classes.show.gradebook, {
              classId: classItem?._id,
            })}
            tabIndex={isSelectedItem ? 0 : -1}
            variant="ghost"
            px={2}
            leftIcon={<Icon name="gradebook" />}
            size="sm"
            aria-label={t('classList.gradebookTooltip')}
          >
            <Text as="span" isTruncated>
              {t('classList.gradebook')}
            </Text>
          </Button>
        </Tooltip>
        <Tooltip variant="dark" label={t('classList.studentsTooltip')}>
          <Button
            ref={lastElementRef as React.RefObject<HTMLButtonElement>}
            as={Link}
            to={generatePath(routes.classes.show.roster.root, {
              classId: classItem?._id,
            })}
            tabIndex={isSelectedItem ? 0 : -1}
            variant="ghost"
            px={2}
            leftIcon={<Icon name="user-outlined" />}
            iconSpacing={0}
            gap={2}
            size="sm"
            aria-label={t('classList.studentsTooltip')}
          >
            <Text as="span" isTruncated>
              {t('classList.students')}
            </Text>
            <Tag
              variant={
                classItem?.studentCount ? 'successOutline' : 'dangerOutline'
              }
              borderRadius="xl"
              display="flex"
              justifyContent="center"
              px={1.5}
              minH="16px"
              minW="23px"
            >
              <Text variant="tagXs" lineHeight="0.625rem">
                {classItem?.studentCount}
              </Text>
            </Tag>
          </Button>
        </Tooltip>
      </CardFooter>
    </Card>
  );
};

export default ClassCard;
