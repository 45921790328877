import { get } from 'lodash-es';
import { CODING24 } from '@lon/shared/constants';
import { ApplicationEnum, DistrictPermission } from '@lon/shared/enums';
import {
  GetAclApplicationsQuery,
  GetDistrictSettingsQuery,
} from '@lon/shared/requests';

const codingModule = {
  name: 'coding',
  operations: [
    {
      name: 'view',
    },
  ],
};
const learningBotModule = {
  name: 'learningBot',
  operations: [
    {
      name: 'access',
    },
  ],
};

const getSettingsModulePlaceholder = (
  data?: GetDistrictSettingsQuery,
  application?: ApplicationEnum
) => {
  const rosterSettings = [
    {
      key: 'districtSettingsRoster.teacherCanCreateClasses',
      name: 'create',
    },
    {
      key: 'districtSettingsRoster.teacherCanEditClassRoster',
      name: 'addStudents',
    },
    {
      key: 'districtSettingsRoster.teacherCanAddAdditionalTeacher',
      name: 'addTeachers',
    },
  ];
  const umSettings = [
    {
      key: 'districtSettingsUserManagement.teacherCanEditStudentPassword',
      name: 'editPassword',
    },
    {
      key: 'districtSettingsUserManagement.teacherCanImpersonateStudents',
      name: 'impersonate',
    },
    {
      key: 'districtSettingsUserManagement.teacherCanCreateStudents',
      name: 'create',
    },
  ];
  const lessonPlanner = [
    {
      key: 'districtSettingsLessonPlanner.teacherCanUseAiAssistedLessonPlan',
      name: 'access',
    },
  ];
  const learningBot = [
    {
      key: 'districtSettingsKnowledgeBase.teacherCanUsePerryBot',
      name: 'access',
    },
  ];

  const settings = [];

  settings.push(
    {
      name: 'classes',
      operations: rosterSettings?.reduce<Record<string, string>[]>(
        (acc, item) => {
          if (get(data, item.key)) {
            acc.push({ name: item.name });
          }
          return acc;
        },
        []
      ),
    },
    {
      name: 'students',
      operations: umSettings?.reduce<Record<string, string>[]>((acc, item) => {
        if (get(data, item.key)) {
          acc.push({ name: item.name });
        }
        return acc;
      }, []),
    }
  );

  if (application !== ApplicationEnum.MATHNATION_SUIT) {
    settings.push({
      name: 'learningBot',
      operations: learningBot?.reduce<Record<string, string>[]>((acc, item) => {
        if (get(data, item.key)) {
          acc.push({ name: item.name });
        }
        return acc;
      }, []),
    });

    if (application === ApplicationEnum.TEACHER_SUIT) {
      settings.push({
        name: 'lessonPlanner',
        operations: lessonPlanner?.reduce<Record<string, string>[]>(
          (acc, item) => {
            if (get(data, item.key)) {
              acc.push({ name: item.name });
            }
            return acc;
          },
          []
        ),
      });
    } else {
      settings.push({
        name: 'lessonPlanner',
        operations: [{ name: 'access' }],
      });
    }
  }

  return settings;
};

export const mergeSuiteAclSettings = (
  application: any,
  data?: GetAclApplicationsQuery,
  settings?: GetDistrictSettingsQuery
) => {
  const hasCodingAccess = settings?.activeContentGroups?.some(
    ({ id }) => id === CODING24
  );

  if (
    application === ApplicationEnum.MATHNATION_SUIT ||
    application === ApplicationEnum.TEACHER_SUIT ||
    application === ApplicationEnum.CAMPUS_LEADER_SUIT ||
    application === ApplicationEnum.CAMPUS_CURRICULUM_LEADER_SUIT ||
    application === ApplicationEnum.DISTRICT_CURRICULUM_LEADER_SUIT
  ) {
    const districtSettingsModules = getSettingsModulePlaceholder(
      settings,
      application
    );

    return [
      ...(data?.aclApplications || []),
      {
        modules: [
          ...districtSettingsModules,
          hasCodingAccess ? codingModule : {},
        ],
        name: null,
        permissionName:
          application === ApplicationEnum.MATHNATION_SUIT
            ? DistrictPermission.MATHNATION_SUIT
            : DistrictPermission.TEACHER_SUIT,
      },
    ];
  } else if (application === ApplicationEnum.STUDENT_SUIT) {
    const canUSeLearningBot =
      settings?.districtSettingsKnowledgeBase?.studentCanUsePerryBot;
    return [
      ...(data?.aclApplications || []),
      {
        modules: [
          hasCodingAccess ? codingModule : {},
          canUSeLearningBot ? learningBotModule : {},
        ],
        name: null,
        permissionName: DistrictPermission.STUDENT_SUIT,
      },
    ];
  }

  return data?.aclApplications;
};
